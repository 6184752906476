import {
CREATE_VENTE,
RETRIEVE_VENTES,
UPDATE_VENTE,
DELETE_VENTE,
DELETE_ALL_VENTES
} from "./types";

import VentesDataService from "../services/vente.service";

export const createVentes = (Nom,Categorie, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await VentesDataService.create({ Nom , Categorie, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_VENTE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await VentesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveVentes = () => async (dispatch) => {

  try {
    const res = await VentesDataService.getAll();

    dispatch({
      type: RETRIEVE_VENTES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateVente = (id, data) => async (dispatch) => {
  try {
    const res = await VentesDataService.update(id, data);

    dispatch({
      type: UPDATE_VENTE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteVente = (id) => async (dispatch) => {
  try {
    await VentesDataService.delete(id);

    dispatch({
      type: DELETE_VENTE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllVentes = () => async (dispatch) => {
  try {
    const res = await VentesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_VENTES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findVentesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await VentesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_VENTES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};