import React, { Component } from "react";
import { connect } from "react-redux";
import { updateAutre, deleteAutre } from "../actions/autre";
import AutresDataService from "../services/autre.service";
import UploadService from "../services/autre.service";

class Autre extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeDescription=this.onChangeDescription.bind(this);
    this.getAutre = this.getAutre.bind(this);
    this.removeAutre = this.removeAutre.bind(this);

    this.state = {
      currentAutre: {
        id: null,
        Nom: "",
        Description: "",
        published:false
      },
      message: "",
      selectedFiles: undefined,
      progress: 0,
      fileInfos: [],
      fileName:"",
    };
  }

  componentDidMount() {
    this.getAutre(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentAutre: {
          ...prevState.currentAutre,
          Nom: Nom,
        },
      };
    });
  }

  onChangeDescription(e) {
    const Description = e.target.value;

    this.setState(function (prevState) {
      return {
        currentAutre: {
          ...prevState.currentAutre,
          Description: Description,
        },
      };
    });
  }


  selectFile =(event) =>{
    this.setState({
          selectedFiles: event.target.files,
        }
    );
  }

  upload= () => {



    if (this.state.selectedFiles) {
    let currentFile=this.state.selectedFiles[0];
    let fileName = 'https://api.attirail.fr/api/fichierautres/files/' + currentFile.name;
    this.setState({
      progress: 0,
      currentFile: currentFile,
    });
    this.setState(function (prevState) {
      return {
        currentAutre: {
          ...prevState.currentAutre,
          Img1: fileName,
        },
      };
    });


      UploadService.upload(currentFile, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }).then((response) => {
        this.setState({
              message: response.data.message,
            }
        );
        return UploadService.getFiles();
      })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .updateAutre(this.state.currentAutre.id, this.state.currentAutre)
            .then((response) => {
              console.log(response);

              this.setState({message: "Le produit a été ajoutée"});
            })
            .catch((e) => {
              console.log(e);
            });
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles: undefined,
      });
    }

    if (!this.state.selectedFiles) {
        this.props
            .updateAutre(this.state.currentAutre.id, this.state.currentAutre)
            .then((reponse) => {
              console.log(reponse);

              this.setState({message: "The autre was updated successfully!"});
            })
            .catch((e) => {
              console.log(e);
            });
      }

    this.setState(function (prevState) {
      return {
        currentAutre: {
          ...prevState.currentAutre,
          published:true,
        },
      };
    })
    }


  getAutre(id) {
    AutresDataService.get(id)
      .then((response) => {
        this.setState({
          currentAutre: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  removeAutre() {
    this.props
      .deleteAutre(this.state.currentAutre.id)
      .then(() => {
        this.props.history.push("/autres");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentAutre , currentFile,progress,
      message} = this.state;

    return (
      <div>
        {currentAutre ? (
            <div>
              {!currentAutre.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentAutre.Nom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentAutre.Nom}
                    onChange={this.onChangeNom}
                />

                <label htmlFor="Description">Description</label>
                <input
                    type="text"
                    className="form-control"
                    id="Description"
                    value={currentAutre.Description}
                    onChange={this.onChangeDescription}
                />
              </div>
              <div>
                {currentFile && (
                    <div className="progress">
                      <div
                          className="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{width: progress + "%"}}
                      >
                        {progress}%
                      </div>
                    </div>
                )}
                <p>Veuillez ajouter la nouvelle photo</p>
                <label className="btn btn-default">
                  <input type="file" onChange={this.selectFile}/>
                </label>



                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentAutre.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeAutre}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.upload}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentAutre.published===true && (
                <div>
                  <a
                      href="https://gestion.attirail.fr/autres"
                  >
                    Revenir à la liste des "Autres"
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur cette produit n'éxiste pas</p>
            <a
                href="https://gestion.attirail.fr/autres"
            >
              Revenir à la liste des "Autres"
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, { updateAutre, deleteAutre })(Autre);
