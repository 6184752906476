import http from "../../http-common";

class VentesDataService {

  upload(files, onUploadProgress) {
    let formData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('file', files[i]);
    }

    return http.post("/fichierventes/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/fichierventes/getListFiles");
  }

  getAll() {
    return http.get("/ventes");
  }

  get(id) {
    return http.get(`/ventes/${id}`);
  }

  create(data) {
    return http.post("/ventes", data);

  }

  update(id, data) {
    return http.put(`/ventes/${id}`, data);

  }

  delete(id) {
    return http.delete(`/ventes/${id}`);
  }

  deleteAll() {
    return http.delete(`/ventes`);
  }

  findByTitle(Nom) {
    return http.get(`/ventes?nom=${Nom}`);
  }
}

export default new VentesDataService();