import React, { Component } from "react";
import { connect } from "react-redux";
import {createDumpers} from "../actions/dumpers";
import UploadService from "../services/dumper.service";

class AddDumper extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeVolumeM = this.onChangeVolumeM.bind(this);
    this.onChangeChargeM= this.onChangeChargeM.bind(this);

    this.newDumper = this.newDumper.bind(this);

    this.state = {
      currentDumper: {
        id: null,
        Nom: "",
        Charge_max:"",
        Volume_max:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile1 =(event) =>{
    this.setState({
      selectedFiles1: event.target.files,
    }
    );
  }
  selectFile2 =(event) =>{
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  noSelectFile=(event)=> {
    this.setState({
      selectedFiles2:"NULL",
    })

  }




  upload= () => {






    if (!this.state.selectedFiles2) {
      this.setState({
        message1: "Veuillez cocher la case si vous n'avez pas de fiche technique! ",
      })
    }

    if (!this.state.selectedFiles1) {
      this.setState({
        message: "Veuillez selectionner 3 images ! ",
      })
    }

    if(this.state.selectedFiles1 && this.state.selectedFiles2) {
      console.log(this.state.selectedFiles1.length);


      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images ! ",
        })
      }

      if ((this.state.selectedFiles1).length === 3 ) {
        let currentFile1 = this.state.selectedFiles1[0];

        let fileName1 = 'https://api.attirail.fr/api/fichierdumpers/files/' + currentFile1.name;
        let currentFile2 = this.state.selectedFiles1[1];
        let fileName2 = 'https://api.attirail.fr/api/fichierdumpers/files/' + currentFile2.name;
        let currentFile3 = this.state.selectedFiles1[2];
        let fileName3 = 'https://api.attirail.fr/api/fichierdumpers/files/' + currentFile3.name;
        let currentFile4 = this.state.selectedFiles2[0];
        let fileName4 = 'https://api.attirail.fr/api/fichierdumpers/files/' + currentFile4.name;

        if (this.state.selectedFiles2 === "NULL") {
          var files = [currentFile1, currentFile2, currentFile3];
          fileName4 = "NULL";

        } else {

          var files = [currentFile1, currentFile2, currentFile3, currentFile4];
        }


        const {Nom, Charge_max, Volume_max} = this.state;
        this.setState({
          progress: 0,
          currentFile1: currentFile1,
          fileName1: fileName1,
          currentFile2: currentFile2,
          fileName2: fileName2,
          currentFile3: currentFile3,
          fileName3: fileName3,
          currentFile4: currentFile4,
          fileName4: fileName4,
          message1: "",
        });

        UploadService.upload(files, (event) => {
          this.setState({
            progress: Math.round((100 * event.loaded) / event.total),
          });
        })
            .then((response) => {
              this.setState({
                    message: response.data.message,
                  }
              );
              return UploadService.getFiles();
            })
            .then((files) => {
              this.setState({
                fileInfos: files.data,
              });
            }).catch(() => {
          this.setState({
            progress: 0,
            message: "Could not upload the file!",
            currentFile: undefined,
          });
        });

        this.props
            .createDumpers(Nom, "dumpers", Charge_max, Volume_max, fileName1, fileName2, fileName3, fileName4)
            .then((data) => {
              this.setState({
                id: data.id,
                Nom: data.Nom,
                Charge_max: data.Charge_max,
                Volume_max: data.Volume_max,

                submitted: true,

              });
              console.log(data);
            }).catch((e) => {
          console.log(e)
        })
        this.setState({
          selectedFiles1: undefined,
          selectedFiles2: undefined,
        });

      }
    }
  }


  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeVolumeM(e) {
    this.setState({
      Volume_max: e.target.value,
    });
  }

  onChangeChargeM(e) {
    this.setState({
      Charge_max: e.target.value,
    });
  }

  newDumper() {
    this.setState({

      id: null,
      Nom: "",
      Charge_max:"",
      Volume_max:"",
      Img1:"",
      Img2:"",
      Img3:"",
      Fiche_technique: ""

    });
  }

  render() {
    const {
      selectedFiles1,
      selectedFiles2,
      currentFile1,
      currentFile2,
      currentFile3,
      currentFile4,
      progress,
      message,
        message1

    } = this.state;

    return (
        <div>
        <div>

          {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
              <div className="progress">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progress + "%"}}
                >
                  {progress}%
                </div>
              </div>
          )}

          <div className="alert alert-light" role="alert">
            <p>{message}</p>
            <p>{message1}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newDumper} href="http://gestion.attirail.fr/dumpers">
              Revenir à la liste des dumpers
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
              />
              <label htmlFor="Charge_max">Charge Max</label>
              <input
                  type="text"
                  className="form-control"
                  id="Charge_max"
                  value={this.state.Charge_max}
                  onChange={this.onChangeChargeM}
              />


              <label htmlFor="Volume_max">Volume Max</label>
              <input
                  type="text"
                  className="form-control"
                  id="Volume_max"
                  value={this.state.Volume_max}
                  onChange={this.onChangeVolumeM}
              />
            </div>
            <p> Veuillez selectionner 3 images (obligatoire) : </p>
            <label className="btn btn-default">
              <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
            </label>
            <p> Veuillez selectionner la fiche technique : </p>
            <label className="btn btn-default>">
              <input type="file"  onChange={this.selectFile2}/>
            </label>
            <p><input id="check" type="checkbox"
                      onChange={this.noSelectFile}/>
              Je n'ai pas de fiche technique pour ce produit
            </p>
            <button
                className="btn btn-success"
                disabled={!selectedFiles1 && !selectedFiles2}
                onClick={this.upload}
            >
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }


}


export default connect(null, { createDumpers })(AddDumper);


