import {
CREATE_NACELLE,
RETRIEVE_NACELLES,
UPDATE_NACELLE,
DELETE_NACELLE,
DELETE_ALL_NACELLES
} from "./types";

import NacellesDataService from "../services/nacelle.service";

export const createNacelles = (Nom, Categorie, Hauteur_max, Charge_max, Longueur,Largeur,Hauteur, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await NacellesDataService.create({ Nom, Categorie, Hauteur_max, Charge_max, Longueur,Largeur,Hauteur, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_NACELLE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await NacellesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveNacelles = () => async (dispatch) => {

  try {
    const res = await NacellesDataService.getAll();

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateNacelle = (id, data) => async (dispatch) => {
  try {
    const res = await NacellesDataService.update(id, data);

    dispatch({
      type: UPDATE_NACELLE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteNacelle = (id) => async (dispatch) => {
  try {
    await NacellesDataService.delete(id);

    dispatch({
      type: DELETE_NACELLE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllNacelles = () => async (dispatch) => {
  try {
    const res = await NacellesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_NACELLES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findNacellesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await NacellesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_NACELLES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};