import {
CREATE_ENGIN,
RETRIEVE_ENGINS,
UPDATE_ENGIN,
DELETE_ENGIN,
DELETE_ALL_ENGINS
} from "./types";

import EnginsDataService from "../services/engin.service";

export const createEngins = (Nom,Categorie, Description,Img1,Fiche_technique) => async (dispatch) => {
  try {
    const res = await EnginsDataService.create({ Nom , Categorie,Description, Img1,Fiche_technique});

    dispatch({
      type: CREATE_ENGIN,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await EnginsDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveEngins = () => async (dispatch) => {

  try {
    const res = await EnginsDataService.getAll();

    dispatch({
      type: RETRIEVE_ENGINS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateEngin = (id, data) => async (dispatch) => {
  try {
    const res = await EnginsDataService.update(id, data);

    dispatch({
      type: UPDATE_ENGIN,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteEngin = (id) => async (dispatch) => {
  try {
    await EnginsDataService.delete(id);

    dispatch({
      type: DELETE_ENGIN,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllEngins = () => async (dispatch) => {
  try {
    const res = await EnginsDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_ENGINS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findEnginsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await EnginsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_ENGINS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};