import React, { Component } from "react";
import { connect } from "react-redux";
import {createCategories} from "../actions/categories";
import UploadService from "../services/categorie.service";

class AddCategorie extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.newCategorie = this.newCategorie.bind(this);

    this.state = {
      id: null,
      Nom: "",
      Img1: "",
      submitted: false,
      selectedFiles: undefined,
      progress: 0,
      message: "",
      fileInfos: [],
      fileName:"",
    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile =(event) =>{
    this.setState({
      selectedFiles: event.target.files,
    }
    );
  }

  upload= () => {

    if ((this.state.selectedFiles).length < 1) {
      this.setState({
        message: "Veuillez selectionner 1 image ! ",
      })
    }

    if ((this.state.selectedFiles).length === 1) {

      let currentFile = this.state.selectedFiles[0];
      let fileName = 'https://api.attirail.fr/api/fichiercategories/files/' + currentFile.name;
      const {Nom} = this.state;
      this.setState({
        progress: 0,
        currentFile: currentFile,
      });

      UploadService.upload(currentFile, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
          .then((response) => {
            this.setState({
                  message: response.data.message,
              fileName : fileName,
                }
            );
            console.log(this.state);

            return UploadService.getFiles();
          })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .createCategories(Nom, fileName)
            .then((data) => {
              this.setState({
                id: data.id,
                Nom: data.Nom,
                submitted: true,

              });
              console.log(data);
            })
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles: undefined,
      });
    }
  }

  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }


  newCategorie() {
    this.setState({

      id: null,
      Nom: "",
      Img1: "",
    });
  }

  render() {
    const {
      selectedFiles,
      currentFile,
      progress,
      message,
        message1


    } = this.state;

    return (
        <div>
        <div>
          {currentFile && (
              <div className="progress">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progress + "%"}}
                >
                  {progress}%
                </div>
              </div>
          )}



          <div className="alert alert-light" role="alert">
            <p>{message}</p>
            <p>{message1}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newCategorie} href="https://gestion.attirail.fr/categories">
              Revenir à la liste des catégories
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom de la catégorie à ajouter</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />
            </div>
            <p>Ajouter une photo :</p>
            <label className="btn btn-default">
              <input type="file" onChange={this.selectFile}/>
            </label>
            <button
                className="btn btn-success"
                disabled={!selectedFiles}
                onClick={this.upload}
            >
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createCategories })(AddCategorie);


