import {
CREATE_CHENILLARD,
RETRIEVE_CHENILLARDS,
UPDATE_CHENILLARD,
DELETE_CHENILLARD,
DELETE_ALL_CHENILLARDS
} from "./types";

import ChenillardsDataService from "../services/chenillard.service";

export const createChenillards = (Nom, Categorie, Charge_max, Autonomie, Img1, Img2, Img3, Fiche_technique) => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.create({ Nom, Categorie, Charge_max, Autonomie, Img1, Img2, Img3, Fiche_technique});

    dispatch({
      type: CREATE_CHENILLARD,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveChenillards = () => async (dispatch) => {

  try {
    const res = await ChenillardsDataService.getAll();

    dispatch({
      type: RETRIEVE_CHENILLARDS,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateChenillard = (id, data) => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.update(id, data);

    dispatch({
      type: UPDATE_CHENILLARD,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteChenillard = (id) => async (dispatch) => {
  try {
    await ChenillardsDataService.delete(id);

    dispatch({
      type: DELETE_CHENILLARD,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllChenillards = () => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_CHENILLARDS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findChenillardsByTitle = (nom) => async (dispatch) => {
  try {
    const res = await ChenillardsDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_CHENILLARDS,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};