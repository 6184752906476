import {
  CREATE_NACELLE,
  RETRIEVE_NACELLES,
  UPDATE_NACELLE,
  DELETE_NACELLE,
  DELETE_ALL_NACELLES
} from "../actions/types";

const initialState = [];

function nacelleReducer(nacelles = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_NACELLE:
      return [...nacelles, payload];

    case RETRIEVE_NACELLES:
      return payload;

    case UPDATE_NACELLE:
      return nacelles.map((nacelle) => {
        if (nacelle.id === payload.id) {
          return {
            ...nacelle,
            ...payload,
          };
        } else {
          return nacelle;
        }
      });

    case DELETE_NACELLE:
      return nacelles.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_NACELLES:
      return [];

    default:
      return nacelles;
  }
}

export default nacelleReducer;