import React, { Component } from "react";
import { connect } from "react-redux";
import {createNacelles} from "../actions/nacelles";
import UploadService from "../services/nacelle.service";

class AddNacelle extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeHauteurM = this.onChangeHauteurM.bind(this);
    this.onChangeChargeM= this.onChangeChargeM.bind(this);
    this.onChangeLongueur= this.onChangeLongueur.bind(this);
    this.onChangeLargeur = this.onChangeLargeur.bind(this);
    this.onChangeHauteur = this.onChangeHauteur.bind(this);

    this.newNacelle = this.newNacelle.bind(this);

    this.state = {
      currentNacelle: {
        id: null,
        Nom: "",
        Hauteur_max:"",
        Categorie:"",
        Charge_max:"",
        Longeur:"",
        Largeur:"",
        Hauteur:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile1 =(event) =>{
    this.setState({
      selectedFiles1: event.target.files,
    }
    );
  }

  selectFile2 =(event) =>{
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  noSelectFile=(event)=> {
    this.setState({
      selectedFiles2:"NULL",
    })

  }

  upload= () => {



    if (!this.state.selectedFiles2) {
      this.setState({
        message1: "Veuillez cocher la case si vous n'avez pas de fiche technique! ",
      })
    }
    if (!this.state.selectedFiles1) {
      this.setState({
        message: "Veuillez selectionner 3 images ! ",
      })
    }

    if(this.state.selectedFiles1 && this.state.selectedFiles2) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images ! ",
        })
      }


    if ((this.state.selectedFiles1).length ===3 ) {

      let currentFile1 = this.state.selectedFiles1[0];
      let fileName1 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile1.name;
      let currentFile2 = this.state.selectedFiles1[1];
      let fileName2 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile2.name;
      let currentFile3 = this.state.selectedFiles1[2];
      let fileName3 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile3.name;
      let currentFile4 = this.state.selectedFiles2[0];
      let fileName4 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile4.name;

      if (this.state.selectedFiles2 === "NULL") {
        var files = [currentFile1, currentFile2, currentFile3];
        fileName4 = "NULL";

      } else {

        var files = [currentFile1, currentFile2, currentFile3, currentFile4];
      }


      const {
        Nom, Hauteur_max, Charge_max, Longueur,
        Largeur, Hauteur
      } = this.state;
      this.setState({
        progress: 0,
        currentFile1: currentFile1,
        fileName1: fileName1,
        currentFile2: currentFile2,
        fileName2: fileName2,
        currentFile3: currentFile3,
        fileName3: fileName3,
        currentFile4: currentFile4,
        fileName4: fileName4,
      });

      UploadService.upload(files, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
          .then((response) => {
            this.setState({
                  message: response.data.message,
                  message1:"",
                }
            );
            return UploadService.getFiles();
          })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
        });
      });

      this.props
          .createNacelles(Nom, "nacelles", Hauteur_max, Charge_max, Longueur, Largeur, Hauteur, fileName1, fileName2, fileName3, fileName4)
          .then((data) => {
            this.setState({
              id: data.id,
              Nom: data.Nom,
              Hauteur_max: data.Hauteur_max,
              Charge_max: data.Charge_max,
              Longueur: data.Longueur,
              Largeur: data.Largeur,
              Hauteur: data.Hauteur,

              submitted: true,

            });
            console.log(data);
          }).catch((e) => {
        console.log(e)
      })
      this.setState({
        selectedFiles1: undefined,
        selectedFiles2: undefined,
      });

    }
  }}

  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeHauteurM(e) {
    this.setState({
      Hauteur_max: e.target.value,
    });
  }

  onChangeChargeM(e) {
    this.setState({
      Charge_max: e.target.value,
    });
  }

  onChangeLongueur(e) {
    this.setState({
      Longueur: e.target.value,
    });
  }

  onChangeLargeur(e) {
    this.setState({
      Largeur: e.target.value,
    });
  }

  onChangeHauteur(e) {
    this.setState({
      Hauteur: e.target.value,
    });
  }

  newNacelle() {
    this.setState({

      id: null,
      Nom: "",
      Hauteur_max:"",
      Categorie:"",
      Charge_max:"",
      Longeur:"",
      Largeur:"",
      Hauteur:"",
      Img1:"",
      Img2:"",
      Img3:"",
      Fiche_technique: "",

    });
  }

  render() {
    const {
      selectedFiles1,
      selectedFiles2,
      currentFile1,
      currentFile2,
      currentFile3,
      currentFile4,
      progress,
      message,
        message1,

    } = this.state;

    return (
        <div>
        <div>

          {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
              <div className="progress">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progress + "%"}}
                >
                  {progress}%
                </div>
              </div>
          )}
          <div className="alert alert-light" role="alert">
           <p>{message}</p>
            <p>{message1}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newNacelle} href="https://gestion.attirail.fr/nacelles">
              Ajouter une nouvelle nacelle
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
              />
              <label htmlFor="Hauteur_max">Hauteur Max</label>
              <input
                  type="text"
                  className="form-control"
                  id="Hauteur_max"
                  value={this.state.Hauteur_max}
                  onChange={this.onChangeHauteurM}
              />
              <label htmlFor="Charge_max">Charge Max</label>
              <input
                  type="text"
                  className="form-control"
                  id="Charge_max"
                  value={this.state.Charge_max}
                  onChange={this.onChangeChargeM}
              />


              <label htmlFor="Longueur">Longueur</label>
              <input
                  type="text"
                  className="form-control"
                  id="Longueur"
                  value={this.state.Longeur}
                  onChange={this.onChangeLongueur}
              />
              <label htmlFor="Largeur">Largeur</label>
              <input
                  type="text"
                  className="form-control"
                  id="Largeur"
                  value={this.state.Largeur}
                  onChange={this.onChangeLargeur}
              />
              <label htmlFor="Hauteur">Hauteur </label>
              <input
                  type="text"
                  className="form-control"
                  id="Hauteur"
                  value={this.state.Hauteur}
                  onChange={this.onChangeHauteur}
              />
            </div>
            <p>Veuillez selectionner 3 images (obligatoire)</p>
            <label className="btn btn-default">
              <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
            </label>

            <label className="btn btn-default>">  Veuillez selectionner la fiche technique :
              <input type="file"  onChange={this.selectFile2}/>
            </label>
            <p><input id="check" type="checkbox"
                      onChange={this.noSelectFile}/>
              Je n'ai pas de fiche technique pour ce produit
            </p>
            <button
                className="btn btn-success"
                disabled={!selectedFiles1 && !selectedFiles2}
                onClick={this.upload}
            >
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }


}


export default connect(null, { createNacelles })(AddNacelle);


