import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrievePelles,
  findPellesByTitle,
  deleteAllPelles,
} from "../actions/pelles";
import { Link } from "react-router-dom";

class PellesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActivePelle = this.setActivePelle.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllPelles = this.removeAllPelles.bind(this);

    this.state = {
      currentPelle: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrievePelles();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentPelle: null,
      currentIndex: -1,
    });
  }

  setActivePelle(pelle, index) {
    this.setState({
      currentPelle: pelle,
      currentIndex: index,
    });
  }

  removeAllPelles() {
    this.props
      .deleteAllPelles()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findPellesByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentPelle, currentIndex } = this.state;
    const { pelles } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher une pelle"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Rechercher
              </button>
              <div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des pelles</h4>

          <ul className="list-group">
            {pelles &&
            pelles.map((pelle, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActivePelle(pelle)}
                    key={pelle.id}

                >
                  <ul>{pelle.Nom}
                  </ul>
                  <tr>
                  <td><img className="img" src={pelle.Img1} alt={pelle.Img1}/></td>
                  <td><img className="img" src={pelle.Img2} alt={pelle.Img2}/></td>
                  <td><img className="img" src={pelle.Img3} alt={pelle.Img3}/></td>
                  </tr>
                </li>


            ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllPelles}
          >
            Supprimer toutes les pelles
          </button>
        </div>
        <div className="col-md-6">
          {currentPelle ? (
            <div>
              <h4>Pelles</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentPelle.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentPelle.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/pelles/" + currentPelle.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Cliquer sur une pelle ou  </p>
              <p>  <a className="nav-item"
                      href="https://gestion.attirail.fr/addpelles" type="button"> Ajouter une pelle </a>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pelles: state.pelles,
  };
};

export default connect(mapStateToProps, {
  retrievePelles,
  findPellesByTitle,
  deleteAllPelles,
})(PellesList);
