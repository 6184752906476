import React, { Component } from "react";
import { connect } from "react-redux";
import {createEngins} from "../actions/engins";
import UploadService from "../services/engin.service";

class AddEngin extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeDescription=this.onChangeDescription.bind(this);
    this.newEngin = this.newEngin.bind(this);

    this.state = {
      currentEngin: {
        id: null,
        Nom: "",
        Description:"",
        Img1:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,

      progress: 0,
      fileName:"",

      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile =(event) =>{
    this.setState({
      selectedFiles1: event.target.files,
    }
    );
  }

  selectFile2=(event) =>{
    this.setState({
      selectedFiles2: event.target.files,
    })
  }

  upload= () => {

    if (!this.state.selectedFiles1) {
      this.setState({
        message: "Veuillez selectionner 1 image ! ",
      })
    }

    if (!this.state.selectedFiles2) {
      this.setState({
        message1: "Veuillez cocher la case si vous n'avez pas de fiche technique! ",
      })
    }
    if(this.state.selectedFiles1 && this.state.selectedFiles2) {


      if (this.state.selectedFiles1) {

        let currentFile1 = this.state.selectedFiles1[0];
        let fileName1 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile1.name;

        let currentFile4 = this.state.selectedFiles2[0];
        let fileName4 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile4.name;
        if (this.state.selectedFiles2 === "NULL") {
          var files = [currentFile1];
          fileName4 = "NULL";

        } else {

          var files = [currentFile1,currentFile4];
        }
        const {
          Nom, Description
        } = this.state;
        this.setState({
          progress: 0,
          currentFile1: currentFile1,
          fileName1: fileName1,

          currentFile4: currentFile4,
          fileName4: fileName4,
        });

        UploadService.upload(files, (event) => {
          this.setState({
            progress: Math.round((100 * event.loaded) / event.total),
          });
        })
            .then((response) => {
              this.setState({
                    message: response.data.message,
                    message2:"",
                  }
              );
              return UploadService.getFiles();
            })
            .then((files) => {
              this.setState({
                fileInfos: files.data,
              });
            }).catch(() => {
          this.setState({
            progress: 0,
            message: "Could not upload the file!",
            currentFile: undefined,
          });
        });

        this.props
            .createEngins(Nom,'engins',Description, fileName1, fileName4)
            .then((data) => {
              this.setState({
                id: data.id,
                Nom: data.Nom,
                Description: data.Description,

                submitted: true,
              });

            })
        this.setState({
          selectedFiles1: undefined,
          selectedFiles2: undefined,
        });

      }
    }}

  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      Description: e.target.value,
    });
  }


  newEngin() {
    this.setState({

      id: null,
      Nom: "",
      Description:"",
      Img1: "",
    });
  }

  noSelectFile=(event)=> {
    this.setState({
      selectedFiles2:"NULL",
    })

  }

  render() {
    const {
      selectedFiles1,
      selectedFiles2,
      currentFile1,

      currentFile4,
      progress,
      message,
      message1


    } = this.state;

    return (
        <div>
        <div>
          {currentFile1 && currentFile4 && (
              <div className="progress">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progress + "%"}}
                >
                  {progress}%
                </div>
              </div>
          )}



          <div className="alert alert-light" role="alert">
            <p>{message}</p>
            <p>{message1}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newEngin} href="https://gestion.attirail.fr/engins">
              Revenir à la liste des engins-TP
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom de l'engin à ajouter</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />
              <label htmlFor="Description">Description du produit</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Description}
                  onChange={this.onChangeDescription}
                  name="Nom"
              />
            </div>
            <p>Ajouter une photo :</p>
            <label className="btn btn-default">
              <input type="file" onChange={this.selectFile}/>
            </label>

            <p>Veuillez selectionner la fiche technique :</p>
            <label className="btn btn-default>">
              <input type="file"  onChange={this.selectFile2}/>
            </label>
            <p><label><input id="check" type="checkbox"
                             onChange={this.noSelectFile}/>
              Je n'ai pas de fiche technique pour ce produit
            </label></p>
            <button
                className="btn btn-success"
                disabled={!selectedFiles1 && !selectedFiles2}
                onClick={this.upload}
            >
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createEngins })(AddEngin);


