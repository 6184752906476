import React, { Component } from "react";
import { connect } from "react-redux";
import { updateEngin, deleteEngin } from "../actions/engins";
import EnginsDataService from "../services/engin.service";
import UploadService from "../services/engin.service";

class Engin extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeDescription=this.onChangeDescription.bind(this);
    this.getEngin = this.getEngin.bind(this);
    this.removeEngin = this.removeEngin.bind(this);

    this.state = {
      currentEngin: {
        id: null,
        Nom: "",
        Description: "",
        Fiche_technique:"",
        published:false
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,

      progress: 0,
      fileInfos: [],
      fileName:"",
      progress4: 0,
      fileName4: "",
    };
  }

  componentDidMount() {
    this.getEngin(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEngin: {
          ...prevState.currentEngin,
          Nom: Nom,
        },
      };
    });
  }

  onChangeDescription(e) {
    const Description = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEngin: {
          ...prevState.currentEngin,
          Description: Description,
        },
      };
    });
  }


  selectFile =(event) =>{
    this.setState({
          selectedFiles1: event.target.files,
        }
    );
  }



  upload = () => {


        if (this.state.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];

          let currentFile4 = this.state.selectedFiles2[0];
          let fileName1 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile1.name;

          let fileName4 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile4.name;
          var files = [currentFile1, currentFile4];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentEngin: {
                ...prevState.currentEngin,
                Img1: fileName1,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateEngin(this.state.currentEngin.id, this.state.currentEngin)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "L'engin was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
            selectedFiles2: undefined,

          });

        }


        if (!this.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName4 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile4.name;
          var files = [currentFile4];
          this.setState({
            progress: 0,

            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentEngin: {
                ...prevState.currentEngin,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateEngin(this.state.currentEngin.id, this.state.currentEngin)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The engin was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles2: undefined,
          });

        }

        if (this.state.selectedFiles1 && !this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];


          let fileName1 = 'https://api.attirail.fr/api/fichierengins/files/' + currentFile1.name;

          var files = [currentFile1];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,


          });
          this.setState(function (prevState) {
            return {
              currentEngin: {
                ...prevState.currentEngin,
                Img1: fileName1,



              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateEngin(this.state.currentEngin.id, this.state.currentEngin)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The engin was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
          });

        }

        this.setState(function (prevState) {
          return {
            currentEngin: {
              ...prevState.currentEngin,
              published: true,
            },
          };
        })



    if (!this.state.selectedFiles1 && !this.state.selectedFiles2) {
      this.props
          .updateEngin(this.state.currentEngin.id, this.state.currentEngin)
          .then((response) => {
            console.log(response);

            this.setState({message: "The engin was updated successfully!"});
          })
          .catch((e) => {
            console.log(e);
          });

      this.setState(function (prevState) {
        return {
          currentEngin: {
            ...prevState.currentEngin,
            published: true,
          },
        };
      })
    }


  }


  getEngin(id) {
    EnginsDataService.get(id)
      .then((response) => {
        this.setState({
          currentEngin: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  selectFile2 = (event) => {
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  removeEngin() {
    this.props
      .deleteEngin(this.state.currentEngin.id)
      .then(() => {
        this.props.history.push("/engins");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentEngin , currentFile,progress,
      message} = this.state;

    return (
      <div>
        {currentEngin ? (
            <div>
              {!currentEngin.published && (
            <div>
          <div className="edit-form">
            <div>
            <h4>Mise à jour de : {currentEngin.Nom}</h4>
            <form>
              <div className="form-group">
                <label htmlFor="Nom">Nom</label>
                <input
                    type="text"
                    className="form-control"
                    id="Nom"
                    value={currentEngin.Nom}
                    onChange={this.onChangeNom}
                />

                <label htmlFor="Description">Description</label>
                <input
                    type="text"
                    className="form-control"
                    id="Description"
                    value={currentEngin.Description}
                    onChange={this.onChangeDescription}
                />
              </div>
              <div>
                {currentFile && (
                    <div className="progress">
                      <div
                          className="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{width: progress + "%"}}
                      >
                        {progress}%
                      </div>
                    </div>
                )}
                <p>Veuillez ajouter la nouvelle photo</p>
                <label className="btn btn-default">
                  <input type="file" onChange={this.selectFile}/>
                </label>

                <p>Fiche technique :</p>
                <label className="btn btn-default>">
                  <input type="file"  onChange={this.selectFile2}/>
                </label>



                <div className="alert alert-light" role="alert">
                  {message}
                </div>


              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentEngin.published ? "Published" : "En cours de modification"}
              </div>
            </form>

          </div>

            <button
              className="badge badge-danger mr-2"
              onClick={this.removeEngin}
            >
              Supprimer
            </button>
            <a
                className="btn badge-success"
                onClick={this.upload}
            >
              Mettre à jour
            </a>
          </div>

            </div>   )}
            {currentEngin.published===true && (
                <div>
                  <a
                      href="https://gestion.attirail.fr/engins"
                  >
                    Revenir à la liste des engins
                  </a>

                </div>)}

          </div>

        ) : (
          <div>
            <br />
            <p>Erreur cet engin n'éxiste pas</p>
            <a
                href="https://gestion.attirail.fr/engins"
            >
              Revenir à la liste des engins
            </a>
          </div>
        )
        }
      </div>

    );
  }
}

export default connect(null, { updateEngin, deleteEngin })(Engin);
