import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { connect } from "react-redux";

import AddCategorie from "./Categorie/components/add-categories.component";
import Categorie from "./Categorie/components/categorie.component";
import CategoriesList from "./Categorie/components/categories-list.component";



import AddPelle from "./Pelle/components/add-pelles.component";
import Pelle from "./Pelle/components/pelle.component";
import PellesList from "./Pelle/components/pelles-list.component";


import AddNacelle from "./Nacelle/components/add-nacelles.component";
import Nacelle from "./Nacelle/components/nacelle.component";
import NacellesList from "./Nacelle/components/nacelles-list.component";

import AddDumper from "./Dumper/components/add-dumpers.component";
import Dumper from "./Dumper/components/dumper.component";
import DumpersList from "./Dumper/components/dumpers-list.component";

import AddRemorque from "./Remorque/components/add-remorques.component";
import Remorque from "./Remorque/components/remorque.component";
import RemorquesList from "./Remorque/components/remorques-list.component";

import AddAutre from "./Autre/components/add-autres.component";
import Autre from "./Autre/components/autre.component";
import AutresList from "./Autre/components/autres-list.component";

import AddVente from "./Vente/components/add-ventes.component";
import Vente from "./Vente/components/vente.component";
import VentesList from "./Vente/components/ventes-list.component";


import AddChenillard from "./Chenillard/components/add-chenillards.component";
import Chenillard from "./Chenillard/components/chenillard.component";
import ChenillardsList from "./Chenillard/components/chenillards-list.component";

import AddEngin from "./Engin/components/add-engins.component";
import Engin from "./Engin/components/engin.component";
import EnginsList from "./Engin/components/engins-list.component";




import Login from "./Session/components/login.component";

import { clearMessage } from "./Session/actions/message";

import { history } from './Session/helpers/history';
import authHeader from "./Session/services/auth-header";




class App extends Component {

  constructor(props) {
    super(props);



    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        headers: authHeader()
      })
      console.log(user);

      var hours = 1; // Reset when storage is more than 24hours
      var now = new Date().getTime();
      var setupTime = localStorage.getItem('setupTime');
      if (setupTime == null) {
        localStorage.setItem('setupTime', now)
      }
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
        window.location.reload("/");
      }
    }

  }




  render() {
    const { showAdminBoard } = this.state;
    return (
      <Router history={history}>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/login"} className="navbar-brand">
            Attirail gestion site
          </Link>
          {showAdminBoard && (
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/categories"} className="nav-link">
                Categories
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/pelles"} className="nav-link">
                Pelles
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/nacelles"} className="nav-link">
                Nacelles
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/dumpers"} className="nav-link">
                Dumpers
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/remorques"} className="nav-link">
                Remorques
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/chenillards"} className="nav-link">
                Chenillards
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/engins"} className="nav-link">
                Engins-TP
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/autres"} className="nav-link">
                Outillages
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/ventes"} className="nav-link">
                Ventes
              </Link>
            </li>

          </div>
          )}
        </nav>

        <div className="container mt-3">
          <Switch>
            <Route exact path= "/pelles" component={PellesList} />
            <Route exact path="/addpelles" component={AddPelle} />
            <Route path="/pelles/:id" component={Pelle} />

            <Route exact path= "/categories" component={CategoriesList} />
            <Route exact path="/addcategories" component={AddCategorie} />
            <Route path="/categories/:id" component={Categorie} />

            <Route exact path= "/nacelles" component={NacellesList} />
            <Route exact path="/addnacelles" component={AddNacelle} />
            <Route path="/nacelles/:id" component={Nacelle} />

            <Route exact path= "/dumpers" component={DumpersList} />
            <Route exact path="/adddumpers" component={AddDumper} />
            <Route path="/dumpers/:id" component={Dumper} />

            <Route exact path= "/remorques" component={RemorquesList} />
            <Route exact path="/addremorques" component={AddRemorque} />
            <Route path="/remorques/:id" component={Remorque} />

            <Route exact path= "/autres" component={AutresList} />
            <Route exact path="/addautres" component={AddAutre} />
            <Route path="/autres/:id" component={Autre} />

            <Route exact path= "/chenillards" component={ChenillardsList} />
            <Route exact path="/addchenillards" component={AddChenillard} />
            <Route path="/chenillards/:id" component={Chenillard} />

            <Route exact path= "/engins" component={EnginsList} />
            <Route exact path="/addengins" component={AddEngin} />
            <Route path="/engins/:id" component={Engin} />

            <Route exact path= "/ventes" component={VentesList} />
            <Route exact path="/addventes" component={AddVente} />
            <Route path="/ventes/:id" component={Vente} />

            <Route exact path={["/", "/login"]} component={Login} />




          </Switch>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(App);

