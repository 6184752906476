import {
  CREATE_CHENILLARD,
  RETRIEVE_CHENILLARDS,
  UPDATE_CHENILLARD,
  DELETE_CHENILLARD,
  DELETE_ALL_CHENILLARDS
} from "../actions/types";

const initialState = [];

function chenillardReducer (chenillards = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CHENILLARD:
      return [...chenillards, payload];

    case RETRIEVE_CHENILLARDS:
      return payload;

    case UPDATE_CHENILLARD:
      return chenillards.map((chenillard) => {
        if (chenillard.id === payload.id) {
          return {
            ...chenillard,
            ...payload,
          };
        } else {
          return chenillard;
        }
      });

    case DELETE_CHENILLARD:
      return chenillards.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_CHENILLARDS:
      return [];

    default:
      return chenillards;
  }
}

export default chenillardReducer;