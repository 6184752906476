import React, { Component } from "react";
import { connect } from "react-redux";
import { updateChenillard, deleteChenillard } from "../actions/chenillard";
import ChenillardsDataService from "../services/chenillard.service";
import UploadService from "../services/chenillard.service";

class Chenillard extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeAutonomie = this.onChangeAutonomie.bind(this);
    this.onChangeChargeM= this.onChangeChargeM.bind(this);

    this.getChenillard = this.getChenillard.bind(this);
    this.removeChenillard = this.removeChenillard.bind(this);

    this.state = {
      currentChenillard: {
        id: null,
        Nom: "",
        Charge_max:"",
        Volume_max:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getChenillard(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentChenillard: {
          ...prevState.currentChenillard,
          Nom: Nom,
        },
      };
    });
  }

  onChangeAutonomie(e) {
    const Autonomie = e.target.value;

    this.setState(function (prevState) {
      return {
        currentChenillard: {
          ...prevState.currentChenillard,
          Autonomie: Autonomie,
        },
      };
    });
  }

  onChangeChargeM(e) {
    const Charge_max = e.target.value;

    this.setState(function (prevState) {
      return {
        currentChenillard: {
          ...prevState.currentChenillard,
          Charge_max: Charge_max,
        },
      };
    });
  }

  selectFile1 =(event) =>{
    this.setState({
          selectedFiles1: event.target.files,
        }
    );
  }

  selectFile2 =(event) =>{
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  upload = () => {


    if (this.state.selectedFiles1) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images !",
        })
      }

      if ((this.state.selectedFiles1).length === 3) {

        if (this.state.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName1 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile3.name;
          let fileName4 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile4.name;
          var files = [currentFile1, currentFile2, currentFile3, currentFile4];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,
            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentChenillard: {
                ...prevState.currentChenillard,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateChenillard(this.state.currentChenillard.id, this.state.currentChenillard)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The chenillard was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
            selectedFiles2: undefined,

          });

        }


        if (this.state.selectedFiles2) {
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName4 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile4.name;

          this.setState({
            progress: 0,

            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentChenillard: {
                ...prevState.currentChenillard,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(currentFile4, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateChenillard(this.state.currentChenillard.id, this.state.currentChenillard)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The remorque was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles2: undefined,
          });

        }

        if (this.state.selectedFiles1 && !this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];

          let fileName1 = 'http://api.attirail.fr/api/fichierchenillards/files/' + currentFile1.name;
          let fileName2 = 'http://api.attirail.fr/api/fichierchenillards/files/' + currentFile2.name;
          let fileName3 = 'http://api.attirail.fr/api/fichierchenillards/files/' + currentFile3.name;

          var files = [currentFile1, currentFile2, currentFile3];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,

          });
          this.setState(function (prevState) {
            return {
              currentChenillard: {
                ...prevState.currentChenillard,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,


              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateChenillard(this.state.currentChenillard.id, this.state.currentChenillard)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The remorque was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
          });

        }

        this.setState(function (prevState) {
          return {
            currentChenillard: {
              ...prevState.currentChenillard,
              published: true,
            },
          };
        })

      }


    }
    if (!this.state.selectedFiles1 && !this.state.selectedFiles2) {
      this.props
          .updateChenillard(this.state.currentChenillard.id, this.state.currentChenillard)
          .then((response) => {
            console.log(response);

            this.setState({message: "The chenillard was updated successfully!"});
          })
          .catch((e) => {
            console.log(e);
          });

      this.setState(function (prevState) {
        return {
          currentChenillard: {
            ...prevState.currentChenillard,
            published: true,
          },
        };
      })
    }

    if (!this.state.selectedFiles1 && this.state.selectedFiles2) {

      let currentFile4 = this.state.selectedFiles2[0];

      let fileName4 = 'https://api.attirail.fr/api/fichierchenillards/files/' + currentFile4.name;
      var files = [ currentFile4];
      this.setState({
        progress: 0,

      });
      this.setState(function (prevState) {
        return {
          currentChenillard: {
            ...prevState.currentChenillard,
            Fiche_technique: fileName4,

          },
        };
      });

      UploadService.upload(files, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }).then((response) => {
        this.setState({
              message: response.data.message,
            }
        );
        return UploadService.getFiles();
      })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .updateChenillard(this.state.currentChenillard.id, this.state.currentChenillard)
            .then((response) => {
              console.log(response);

              this.setState({message: "The chenillard was updated successfully!"});
            })
            .catch((e) => {
              console.log(e);
            });
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles1: undefined,
        selectedFiles2: undefined,

      });

      this.setState(function (prevState) {
        return {
          currentChenillard: {
            ...prevState.currentChenillard,
            published: true,
          },
        };
      })
    }



  }






  getChenillard(id) {
    ChenillardsDataService.get(id)
      .then((response) => {
        this.setState({
          currentChenillard: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  removeChenillard() {
    this.props
      .deleteChenillard(this.state.currentChenillard.id)
      .then(() => {
        this.props.history.push("/chenillards");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentChenillard , currentFile1, currentFile2, currentFile3, currentFile4,progress,
      message} = this.state;

    return (
        <div>
          {currentChenillard ? (
              <div>
                {!currentChenillard.published && (
                    <div>
                      <div className="edit-form">
                        <div>
                          <h4>Mise à jour de : {currentChenillard.Nom}</h4>
                        </div>
                        <form>
                          <div className="form-group">
                            <label htmlFor="Nom">Nom</label>
                            <input
                                type="text"
                                className="form-control"
                                id="Nom"
                                value={currentChenillard.Nom}
                                onChange={this.onChangeNom}
                            />
                            <label htmlFor="Charge_max">Charge Max</label>
                            <input
                                type="text"
                                className="form-control"
                                id="Charge_max"
                                value={currentChenillard.Charge_max}
                                onChange={this.onChangeChargeM}
                            />
                            <label htmlFor="Autonomie">Autonomie</label>
                            <input
                                type="text"
                                className="form-control"
                                id="Autonomie"
                                value={currentChenillard.Autonomie}
                                onChange={this.onChangeAutonomie}
                            />
                          </div>
                          <div>
                            {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
                                <div className="progress">
                                  <div
                                      className="progress-bar progress-bar-info progress-bar-striped"
                                      role="progressbar"
                                      aria-valuenow={progress}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{width: progress + "%"}}
                                  >
                                    {progress}%
                                  </div>
                                </div>
                            )}
                            <p>Veuillez selectionner 3 images :</p>
                            <label className="btn btn-default">
                              <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
                            </label>

                            <p>Fiche technique : </p>
                            <label className="btn btn-default>">
                              <input type="file"  onChange={this.selectFile2}/>
                            </label>

                            <div className="alert alert-light" role="alert">
                              {message}
                            </div>


                          </div>

                          <div className="form-group">
                            <label>
                              <strong>Status:</strong>
                            </label>
                            {currentChenillard.published ? "Published" : "En cours de modification"}
                          </div>
                        </form>
                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.removeChenillard}
                        >
                          Supprimer
                        </button>
                        <a
                            className="btn badge-success"
                            onClick={this.upload}
                        >
                          Mettre à jour
                        </a>
                      </div>

                    </div>   )}
                {currentChenillard.published===true && (
                    <div>
                      <a
                          href="https://gestion.attirail.fr/chenillards"
                      >
                        Revenir à la liste des chenillards
                      </a>

                    </div>)}

              </div>

          ) : (
              <div>
                <br />
                <p>Erreur ce chenillard n'éxiste pas</p>
                <a
                    href="https://gestion.attirail.fr/remorques"
                >
                  Revenir à la liste des chenillards
                </a>
              </div>
          )
          }
        </div>
    );
  }
}

export default connect(null, { updateChenillard, deleteChenillard })(Chenillard);
