import React, { Component } from "react";
import { connect } from "react-redux";
import { updateVente, deleteVente } from "../actions/ventes";
import VentesDataService from "../services/vente.service";
import UploadService from "../services/vente.service";

class Vente extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);


    this.getVente = this.getVente.bind(this);
    this.removeVente = this.removeVente.bind(this);

    this.state = {
      currentVente: {
        id: null,
        Nom: "",

        published: false,
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      progress: 0,
      fileName: "",
      progress2: 0,
      fileName2: "",
      progress3: 0,
      fileName3: "",
      progress4: 0,
      fileName4: "",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getVente(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentVente: {
          ...prevState.currentVente,
          Nom: Nom,
        },
      };
    });
  }


  selectFile1 = (event) => {
    this.setState({
          selectedFiles1: event.target.files,
        }
    );
  }

  selectFile2 = (event) => {
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  upload = () => {


    if (this.state.selectedFiles1) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images !",
        })
      }

      if ((this.state.selectedFiles1).length === 3) {

        if (this.state.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName1 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile3.name;
          let fileName4 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile4.name;
          var files = [currentFile1, currentFile2, currentFile3, currentFile4];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,
            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentVente: {
                ...prevState.currentVente,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateVente(this.state.currentVente.id, this.state.currentVente)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The vente was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
            selectedFiles2: undefined,

          });

        }


        if (this.state.selectedFiles2) {
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName4 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile4.name;

          this.setState({
            progress: 0,

            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentVente: {
                ...prevState.currentVente,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(currentFile4, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateVente(this.state.currentVente.id, this.state.currentVente)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The vente was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles2: undefined,
          });

        }

        if (this.state.selectedFiles1 && !this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];

          let fileName1 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichierventes/files/' + currentFile3.name;

          var files = [currentFile1, currentFile2, currentFile3];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,

          });
          this.setState(function (prevState) {
            return {
              currentVente: {
                ...prevState.currentVente,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,


              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateVente(this.state.currentVente.id, this.state.currentVente)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The vente was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
          });

        }

        this.setState(function (prevState) {
          return {
            currentVente: {
              ...prevState.currentVente,
              published: true,
            },
          };
        })

      }


      }
    if (!this.state.selectedFiles1 && !this.state.selectedFiles2) {
      this.props
          .updateVente(this.state.currentVente.id, this.state.currentVente)
          .then((response) => {
            console.log(response);

            this.setState({message: "The vente was updated successfully!"});
          })
          .catch((e) => {
            console.log(e);
          });

      this.setState(function (prevState) {
        return {
          currentVente: {
            ...prevState.currentVente,
            published: true,
          },
        };
      })
    }


  }


  getVente(id) {
    VentesDataService.get(id)
        .then((response) => {
          this.setState({
            currentVente: response.data,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
  }


  removeVente() {
    this.props
        .deleteVente(this.state.currentVente.id)
        .then(() => {
          this.props.history.push("/ventes");
        })
        .catch((e) => {
          console.log(e);
        });
  }

  render() {
    const {
      currentVente, currentFile1, currentFile2, currentFile3, currentFile4, progress,
      message
    } = this.state;

    return (
        <div>
          {currentVente ? (
              <div>
                {!currentVente.published && (
                    <div>
                      <div className="edit-form">
                        <div>
                          <h4>Mise à jour de : {currentVente.Nom}</h4>
                          <form>
                            <div className="form-group">

                              <label htmlFor="Nom">Nom</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Nom"
                                  value={currentVente.Nom}
                                  onChange={this.onChangeNom}
                              />

                            </div>
                            <div>
                              {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
                                  <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-info progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{width: progress + "%"}}
                                    >
                                      {progress}%
                                    </div>
                                  </div>
                              )}
                              <p>Si MAJ veuillez selectionner 3 images</p>
                              <label className="btn btn-default">
                                <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
                              </label>

                              <p>Fiche technique :</p>
                              <label className="btn btn-default>">
                                <input type="file"  onChange={this.selectFile2}/>
                              </label>


                              <div className="alert alert-light" role="alert">
                                {message}
                              </div>


                            </div>

                            <div className="form-group">
                              <label>
                                <strong>Status:</strong>
                              </label>
                              {currentVente.published ? "Published" : "En cours de modification"}
                            </div>
                          </form>
                        </div>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.removeVente}
                        >
                          Supprimer
                        </button>
                        <a
                            className="btn badge-success"
                            onClick={this.upload}
                        >
                          Mettre à jour
                        </a>
                      </div>

                    </div>)}
                {currentVente.published === true && (
                    <div>
                      <a
                          href="https://gestion.attirail.fr/ventes"
                      >
                        Revenir à la liste des ventes
                      </a>

                    </div>)}

              </div>

          ) : (
              <div>
                <br/>
                <p>Erreur ce produit n'éxiste pas</p>
                <a
                    href="https://gestion.attirail.fr/ventes"
                >
                  Revenir à la liste des ventes
                </a>
              </div>
          )
          }
        </div>
    );
  }
}

export default connect(null, { updateVente, deleteVente })(Vente);