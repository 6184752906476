import {
CREATE_AUTRE,
RETRIEVE_AUTRES,
UPDATE_AUTRE,
DELETE_AUTRE,
DELETE_ALL_AUTRES
} from "./types";

import AutresDataService from "../services/autre.service";

export const createAutres = (Nom,Categorie, Description,Img1) => async (dispatch) => {
  try {
    const res = await AutresDataService.create({ Nom , Categorie,Description, Img1});

    dispatch({
      type: CREATE_AUTRE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await AutresDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveAutres = () => async (dispatch) => {

  try {
    const res = await AutresDataService.getAll();

    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateAutre = (id, data) => async (dispatch) => {
  try {
    const res = await AutresDataService.update(id, data);

    dispatch({
      type: UPDATE_AUTRE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAutre = (id) => async (dispatch) => {
  try {
    await AutresDataService.delete(id);

    dispatch({
      type: DELETE_AUTRE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllAutres = () => async (dispatch) => {
  try {
    const res = await AutresDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_AUTRES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findAutresByTitle = (nom) => async (dispatch) => {
  try {
    const res = await AutresDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_AUTRES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};