import axios from "axios";

const API_URL = "https://api.attirail.fr/api/auth/";


class AuthService {



    login(username, password) {
        return axios
            .post(API_URL + "signin", { username, password })
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem( "user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

}

export default new AuthService();