import React, { Component } from "react";
import { connect } from "react-redux";
import {createPelles} from "../actions/pelles";
import UploadService from "../services/pelle.service";

class AddPelle extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangePoids = this.onChangePoids.bind(this);
    this.onChangeProfondeur= this.onChangeProfondeur.bind(this);
    this.onChangePortee = this.onChangePortee.bind(this);
    this.onChangeHauteurdeT = this.onChangeHauteurdeT.bind(this);
    this.onChangeLongueur= this.onChangeLongueur.bind(this);
    this.onChangeLargeur = this.onChangeLargeur.bind(this);
    this.onChangeHauteur = this.onChangeHauteur.bind(this);

    this.newPelle = this.newPelle.bind(this);

    this.state = {
      currentPelle: {
        id: null,
        Nom: "",
        Poids:"",
        Categorie:"",
        Profondeur_de_fouille:"",
        Portee_laterale:"",
        Hauteur_de_travail:"",
        Longueur:"",
        Largeur:"",
        Hauteur:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: ""
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile1 =(event) =>{
    this.setState({
      selectedFiles1: event.target.files,
    }
    );
  }
  selectFile2 =(event) =>{
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }


  noSelectFile=(event)=> {
    this.setState({
      selectedFiles2:"NULL",
    })

  }


  upload= () => {

        if (!this.state.selectedFiles1) {
      this.setState({
        message: "Veuillez selectionner 3 images ! ",
      })
    }

    if (!this.state.selectedFiles2) {
      this.setState({
        message1: "Veuillez cocher la case si vous n'avez pas de fiche technique! ",
      })
    }
    if(this.state.selectedFiles1 && this.state.selectedFiles2) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images ! ",
        })
      }

    if ((this.state.selectedFiles1).length === 3 ) {

      let currentFile1 = this.state.selectedFiles1[0];
      let fileName1 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile1.name;
      let currentFile2 = this.state.selectedFiles1[1];
      let fileName2 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile2.name;
      let currentFile3 = this.state.selectedFiles1[2];
      let fileName3 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile3.name;
      let currentFile4 = this.state.selectedFiles2[0];
      let fileName4 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile4.name;
      if (this.state.selectedFiles2 === "NULL") {
        var files = [currentFile1, currentFile2, currentFile3];
        fileName4 = "NULL";

      } else {

        var files = [currentFile1, currentFile2, currentFile3, currentFile4];
      }
      const {
        Nom, Poids, Profondeur_de_fouille, Portee_laterale, Hauteur_de_travail, Longueur,
        Largeur, Hauteur
      } = this.state;
      this.setState({
        progress: 0,
        currentFile1: currentFile1,
        fileName1: fileName1,
        currentFile2: currentFile2,
        fileName2: fileName2,
        currentFile3: currentFile3,
        fileName3: fileName3,
        currentFile4: currentFile4,
        fileName4: fileName4,
      });

      UploadService.upload(files, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
          .then((response) => {
            this.setState({
                  message: response.data.message,
                  message2:"",
                }
            );
            return UploadService.getFiles();
          })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
        });
      });

      this.props
          .createPelles(Nom, Poids, "pelles", Profondeur_de_fouille, Portee_laterale, Hauteur_de_travail, Longueur, Largeur, Hauteur, fileName1, fileName2, fileName3, fileName4)
          .then((data) => {
            this.setState({
              id: data.id,
              Nom: data.Nom,
              Profondeur_de_fouille: data.Profondeur_de_fouille,
              Portee_laterale: data.Portee_laterale,
              Hauteur_de_travail: data.Hauteur_de_travail,
              Longueur: data.Longueur,
              Largeur: data.Largeur,
              Hauteur: data.Hauteur,

              submitted: true,
            });

          })
      this.setState({
        selectedFiles1: undefined,
        selectedFiles2: undefined,
      });

    }
  }}

  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangePoids(e) {
    this.setState({
      Poids: e.target.value,
    });
  }

  onChangeProfondeur(e) {
    this.setState({
      Profondeur_de_fouille: e.target.value,
    });
  }

  onChangePortee(e) {
    this.setState({
      Portee_laterale: e.target.value,
    });
  }

  onChangeHauteurdeT(e) {
    this.setState({
      Hauteur_de_travail: e.target.value,
    });
  }

  onChangeLongueur(e) {
    this.setState({
      Longueur: e.target.value,
    });
  }

  onChangeLargeur(e) {
    this.setState({
      Largeur: e.target.value,
    });
  }

  onChangeHauteur(e) {
    this.setState({
      Hauteur: e.target.value,
    });
  }

  newPelle() {
    this.setState({

      id: null,
      Nom: "",
      Poids:"",
      Categorie:"",
      Profondeur_de_fouille:"",
      Portee_laterale:"",
      Hauteur_de_travail:"",
      Longueur:"",
      Largeur:"",
      Hauteur:"",
      Img1:"",
      Img2:"",
      Img3:"",
      Fiche_technique:"",

    });
  }

  render() {
    const {
      selectedFiles1,
      selectedFiles2,
      currentFile1,
      currentFile2,
      currentFile3,
      currentFile4,
      progress,
      message,
        message1

    } = this.state;

    return (
        <div>
          <div>

            {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
                <div className="progress">
                  <div
                      className="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{width: progress + "%"}}
                  >
                    {progress}%
                  </div>
                </div>
            )}

            <div className="alert alert-light" role="alert">
              <p>{message}</p>
              <p>{message1}</p>
            </div>
          </div>

          <div className="submit-form">
            {this.state.submitted ? (
                <div>
                  <h4>You submitted successfully!</h4>
                  <a className="btn btn-success" onClick={this.newPelle} href="https://gestion.attirail.fr/pelles">
                    Ajouter une nouvelle pelle
                  </a>
                </div>
            ) : (
                <div>
                  <div className="form-group">
                    <label htmlFor="Nom">Nom</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Nom"
                        value={this.state.Nom}
                        onChange={this.onChangeNom}
                    />
                    <label htmlFor="Poids">Poids</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Poids"
                        value={this.state.Poids}
                        onChange={this.onChangePoids}
                    />
                    <label htmlFor="Profondeur_de_fouille">Profondeur de fouille</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Profondeur_de_fouille"
                        value={this.state.Profondeur_de_fouille}
                        onChange={this.onChangeProfondeur}
                    />
                    <label htmlFor="Portée_latérale">Portée latérale</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Portée_latérale"
                        value={this.state.Portee_laterale}
                        onChange={this.onChangePortee}
                    />

                    <label htmlFor="Hauteur_de_travail">Hauteur de travail</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Hauteur_de_travail"
                        value={this.state.Hauteur_de_travail}
                        onChange={this.onChangeHauteurdeT}
                    />

                    <label htmlFor="Longueur">Longueur</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Longueur"
                        value={this.state.Longueur}
                        onChange={this.onChangeLongueur}
                    />
                    <label htmlFor="Largeur">Largeur</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Largeur"
                        value={this.state.Largeur}
                        onChange={this.onChangeLargeur}
                    />
                    <label htmlFor="Hauteur">Hauteur </label>
                    <input
                        type="text"
                        className="form-control"
                        id="Hauteur"
                        value={this.state.Hauteur}
                        onChange={this.onChangeHauteur}
                    />
                  </div>
                  <p>Veuillez selectionner 3 images (obligatoire) :</p>
                  <label className="btn btn-default">
                    <input type="file" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
                  </label>

                  <p>Veuillez selectionner la fiche technique :</p>
                  <label className="btn btn-default>">
                    <input type="file"  onChange={this.selectFile2}/>
                  </label>
                  <p><label><input id="check" type="checkbox"
                                   onChange={this.noSelectFile}/>
                    Je n'ai pas de fiche technique pour ce produit
                  </label></p>
                  <button
                      className="btn btn-success"
                      disabled={!selectedFiles1 && !selectedFiles2}
                      onClick={this.upload}
                  >

                    Ajouter
                  </button>
                </div>
            )}
          </div>

        </div>
    );
  }
}


export default connect(null, { createPelles })(AddPelle);


