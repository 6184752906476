import React, { Component } from "react";
import { connect } from "react-redux";
import {createAutres} from "../actions/autre";
import UploadService from "../services/autre.service";

class AddAutre extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeDescription=this.onChangeDescription.bind(this);
    this.newAutre = this.newAutre.bind(this);

    this.state = {
      id: null,
      Nom: "",
      Description:"",
      Img1: "",
      submitted: false,
      selectedFiles: undefined,
      progress: 0,
      message: "",
      fileInfos: [],
      fileName:"",
    };
  }

  componentDidMount() {
    UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      }
      )
    });
  }

  selectFile =(event) =>{
    this.setState({
      selectedFiles: event.target.files,
    }
    );
  }

  upload= () => {

    if ((this.state.selectedFiles).length < 1) {
      this.setState({
        message: "Veuillez selectionner 1 image ! ",
      })
    }

    if ((this.state.selectedFiles).length === 1) {

      let currentFile = this.state.selectedFiles[0];
      let fileName = 'https://api.attirail.fr/api/fichierautres/files/' + currentFile.name;
      const {Nom,Description} = this.state;
      this.setState({
        progress: 0,
        currentFile: currentFile,
        fileName: fileName,
      });

      UploadService.upload(currentFile, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
          .then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .createAutres(Nom,'equipements',Description, fileName)
            .then((data) => {
              this.setState({
                id: data.id,
                Nom: data.Nom,
                Description:data.Description,
                submitted: true,

              });
              console.log(data);
            })
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles: undefined,
      });
    }
  }

  onChangeNom(e) {
    this.setState({
      Nom: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      Description: e.target.value,
    });
  }


  newAutre() {
    this.setState({

      id: null,
      Nom: "",
      Description:"",
      Img1: "",
    });
  }

  render() {
    const {
      selectedFiles,
      currentFile,
      progress,
      message,
        message1


    } = this.state;

    return (
        <div>
        <div>
          {currentFile && (
              <div className="progress">
                <div
                    className="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progress + "%"}}
                >
                  {progress}%
                </div>
              </div>
          )}



          <div className="alert alert-light" role="alert">
            <p>{message}</p>
            <p>{message1}</p>
          </div>
        </div>

    <div className="submit-form">
      {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn btn-success" onClick={this.newAutre} href="https://gestion.attirail.fr/autres">
              Revenir à la liste des "Autres"
            </a>
          </div>
      ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Nom">Nom du produit à ajouter</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Nom}
                  onChange={this.onChangeNom}
                  name="Nom"
              />
              <label htmlFor="Nom">Description du produit</label>
              <input
                  type="text"
                  className="form-control"
                  id="Nom"
                  required
                  value={this.state.Description}
                  onChange={this.onChangeDescription}
                  name="Nom"
              />
            </div>
            <p>Ajouter une photo :</p>
            <label className="btn btn-default">
              <input type="file" onChange={this.selectFile}/>
            </label>
            <button
                className="btn btn-success"
                disabled={!selectedFiles}
                onClick={this.upload}
            >
              Ajouter
            </button>
          </div>
      )}
    </div>

        </div>
    );
  }
}


export default connect(null, { createAutres })(AddAutre);


