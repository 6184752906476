import {
  CREATE_ENGIN,
  RETRIEVE_ENGINS,
  UPDATE_ENGIN,
  DELETE_ENGIN,
  DELETE_ALL_ENGINS
} from "../actions/types";

const initialState = [];

function enginReducer(engins = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ENGIN:
      return [...engins, payload];

    case RETRIEVE_ENGINS:
      return payload;

    case UPDATE_ENGIN:
      return engins.map((engin) => {
        if (engin.id === payload.id) {
          return {
            ...engin,
            ...payload,
          };
        } else {
          return engin;
        }
      });

    case DELETE_ENGIN:
      return engins.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_ENGINS:
      return [];

    default:
      return engins;
  }
}

export default enginReducer;