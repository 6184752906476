import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveChenillards,
  findChenillardsByTitle,
  deleteAllChenillards,
} from "../actions/chenillard";
import { Link } from "react-router-dom";

class ChenillardsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveChenillard = this.setActiveChenillard.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllChenillards = this.removeAllChenillards.bind(this);

    this.state = {
      currentChenillard: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveChenillards();


  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentChenillard: null,
      currentIndex: -1,
    });
  }

  setActiveChenillard(chenillard, index) {
    this.setState({
      currentChenillard: chenillard,
      currentIndex: index,
    });
  }

  removeAllChenillards() {
    this.props
      .deleteAllChenillards()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findChenillardsByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentChenillard, currentIndex } = this.state;
    const { chenillards } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher un chenillard"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Rechercher
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des chenillards</h4>

          <ul className="list-group">
            {chenillards &&
            chenillards.map((chenillard, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActiveChenillard(chenillard)}
                    key={chenillard.id}

                >
                  <ul>{chenillard.Nom}
                  </ul>
                  <tr>
                    <td><img className="img" src={chenillard.Img1} alt={chenillard.Img1}/></td>
                    <td><img className="img" src={chenillard.Img2} alt={chenillard.Img2}/></td>
                    <td><img className="img" src={chenillard.Img3} alt={chenillard.Img3}/></td>
                  </tr>
                </li>


            ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllChenillards}
          >
            Supprimer tous les chenillards
          </button>
        </div>
        <div className="col-md-6">
          {currentChenillard ? (
            <div>
              <h4>Chenillards</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentChenillard.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentChenillard.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/chenillards/" + currentChenillard.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Cliquez sur un chenillard ou  </p>
              <p><a href="https://gestion.attirail.fr/addchenillards"> Ajouter un chenillard</a> </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chenillards: state.chenillards,
  };
};

export default connect(mapStateToProps, {
 retrieveChenillards,
  deleteAllChenillards,
  findChenillardsByTitle
})(ChenillardsList);
