import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePelle, deletePelle } from "../actions/pelles";
import PellesDataService from "../services/pelle.service";
import UploadService from "../services/pelle.service";

class Pelle extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangePoids = this.onChangePoids.bind(this);
    this.onChangeProfondeur = this.onChangeProfondeur.bind(this);
    this.onChangePortee = this.onChangePortee.bind(this);
    this.onChangeHauteurdeTravail = this.onChangeHauteurdeTravail.bind(this);
    this.onChangeLongueur = this.onChangeLongueur.bind(this);
    this.onChangeLargeur = this.onChangeLargeur.bind(this);
    this.onChangeHauteur = this.onChangeHauteur.bind(this);

    this.getPelle = this.getPelle.bind(this);
    this.removePelle = this.removePelle.bind(this);

    this.state = {
      currentPelle: {
        id: null,
        Nom: "",
        Poids: "",
        Categorie: "",
        Profondeur_de_fouille: "",
        Portee_laterale: "",
        Hauteur_de_travail: "",
        Longeur: "",
        Largeur: "",
        Hauteur: "",
        published: false,
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      progress: 0,
      fileName: "",
      progress2: 0,
      fileName2: "",
      progress3: 0,
      fileName3: "",
      progress4: 0,
      fileName4: "",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getPelle(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Nom: Nom,
        },
      };
    });
  }

  onChangePoids(e) {
    const Poids = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Poids: Poids,
        },
      };
    });
  }

  onChangeProfondeur(e) {
    const Profondeur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Profondeur_de_fouille: Profondeur,
        },
      };
    });
  }

  onChangePortee(e) {
    const Portee = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Portee_laterale: Portee,
        },
      };
    });
  }

  onChangeHauteur(e) {
    const Hauteur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Hauteur: Hauteur,
        },
      };
    });
  }

  onChangeLongueur(e) {
    const Longueur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Longueur: Longueur,
        },
      };
    });
  }

  onChangeLargeur(e) {
    const Largeur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Largeur: Largeur,
        },
      };
    });
  }

  onChangeHauteurdeTravail(e) {
    const HauteurdeT = e.target.value;

    this.setState(function (prevState) {
      return {
        currentPelle: {
          ...prevState.currentPelle,
          Hauteur_de_travail: HauteurdeT,
        },
      };
    });
  }


  selectFile1 = (event) => {
    this.setState({
          selectedFiles1: event.target.files,
        }
    );
  }

  selectFile2 = (event) => {
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  upload = () => {


    if (this.state.selectedFiles1) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images !",
        })
      }

      if ((this.state.selectedFiles1).length === 3) {

        if (this.state.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName1 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile3.name;
          let fileName4 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile4.name;
          var files = [currentFile1, currentFile2, currentFile3, currentFile4];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,
            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentPelle: {
                ...prevState.currentPelle,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updatePelle(this.state.currentPelle.id, this.state.currentPelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The pelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
            selectedFiles2: undefined,

          });

        }


        if (this.state.selectedFiles2) {
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName4 = 'http://api.attirail.fr/api/fichierpelles/files/' + currentFile4.name;

          this.setState({
            progress: 0,

            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentPelle: {
                ...prevState.currentPelle,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(currentFile4, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updatePelle(this.state.currentPelle.id, this.state.currentPelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The pelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles2: undefined,
          });

        }

        if (this.state.selectedFiles1 && !this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];

          let fileName1 = 'http://api.attirail.fr/api/fichierpelles/files/' + currentFile1.name;
          let fileName2 = 'http://api.attirail.fr/api/fichierpelles/files/' + currentFile2.name;
          let fileName3 = 'http://api.attirail.fr/api/fichierpelles/files/' + currentFile3.name;

          var files = [currentFile1, currentFile2, currentFile3];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,

          });
          this.setState(function (prevState) {
            return {
              currentPelle: {
                ...prevState.currentPelle,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,


              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updatePelle(this.state.currentPelle.id, this.state.currentPelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The pelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
          });

        }

        this.setState(function (prevState) {
          return {
            currentPelle: {
              ...prevState.currentPelle,
              published: true,
            },
          };
        })

      }


      }


    if (!this.state.selectedFiles1 && !this.state.selectedFiles2) {
      this.props
          .updatePelle(this.state.currentPelle.id, this.state.currentPelle)
          .then((response) => {
            console.log(response);

            this.setState({message: "The pelle was updated successfully!"});
          })
          .catch((e) => {
            console.log(e);
          });

      this.setState(function (prevState) {
        return {
          currentPelle: {
            ...prevState.currentPelle,
            published: true,
          },
        };
      })
    }

    if (!this.state.selectedFiles1 && this.state.selectedFiles2) {

      let currentFile4 = this.state.selectedFiles2[0];

      let fileName4 = 'https://api.attirail.fr/api/fichierpelles/files/' + currentFile4.name;
      var files = [ currentFile4];
      this.setState({
        progress: 0,

      });
      this.setState(function (prevState) {
        return {
          currentPelle: {
            ...prevState.currentPelle,
            Fiche_technique: fileName4,

          },
        };
      });

      UploadService.upload(files, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }).then((response) => {
        this.setState({
              message: response.data.message,
            }
        );
        return UploadService.getFiles();
      })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .updatePelle(this.state.currentPelle.id, this.state.currentPelle)
            .then((response) => {
              console.log(response);

              this.setState({message: "The pelle was updated successfully!"});
            })
            .catch((e) => {
              console.log(e);
            });
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles1: undefined,
        selectedFiles2: undefined,

      });

      this.setState(function (prevState) {
        return {
          currentPelle: {
            ...prevState.currentPelle,
            published: true,
          },
        };
      })

    }





  }


  getPelle(id) {
    PellesDataService.get(id)
        .then((response) => {
          this.setState({
            currentPelle: response.data,
          });
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
  }


  removePelle() {
    this.props
        .deletePelle(this.state.currentPelle.id)
        .then(() => {
          this.props.history.push("/pelles");
        })
        .catch((e) => {
          console.log(e);
        });
  }

  render() {
    const {
      currentPelle, currentFile1, currentFile2, currentFile3, currentFile4, progress,
      message
    } = this.state;

    return (
        <div>
          {currentPelle ? (
              <div>
                {!currentPelle.published && (
                    <div>
                      <div className="edit-form">
                        <div>
                          <h4>Mise à jour de : {currentPelle.Nom}</h4>
                          <form>
                            <div className="form-group">

                              <label htmlFor="Nom">Nom</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Nom"
                                  value={currentPelle.Nom}
                                  onChange={this.onChangeNom}
                              />
                              <label htmlFor="Nom">Poids</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Poids"
                                  value={currentPelle.Poids}
                                  onChange={this.onChangePoids}
                              />
                              <label htmlFor="Nom">Profondeur de fouille</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Profondeur_de_fouille"
                                  value={currentPelle.Profondeur_de_fouille}
                                  onChange={this.onChangeProfondeur}
                              />
                              <label htmlFor="Portée_latérale">Portée latérale</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Portée_latérale"
                                  value={currentPelle.Portee_laterale}
                                  onChange={this.onChangePortee}
                              />

                              <label htmlFor="Hauteur_de_travail">Hauteur de travail</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Hauteur_de_travail"
                                  value={currentPelle.Hauteur_de_travail}
                                  onChange={this.onChangeHauteurdeTravail}
                              />
                              <label htmlFor="Longueur">Longueur</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Longueur"
                                  value={currentPelle.Longueur}
                                  onChange={this.onChangeLongueur}
                              />
                              <label htmlFor="Largeur">Largeur</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Largeur"
                                  value={currentPelle.Largeur}
                                  onChange={this.onChangeLargeur}
                              />
                              <label htmlFor="Hauteur">Hauteur </label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Hauteur"
                                  value={currentPelle.Hauteur}
                                  onChange={this.onChangeHauteur}
                              />
                            </div>
                            <div>
                              {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
                                  <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-info progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{width: progress + "%"}}
                                    >
                                      {progress}%
                                    </div>
                                  </div>
                              )}
                              <p>Si MAJ veuillez selectionner 3 images</p>
                              <label className="btn btn-default">
                                <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
                              </label>

                              <p>Fiche technique :</p>
                              <label className="btn btn-default>">
                                <input type="file"  onChange={this.selectFile2}/>
                              </label>


                              <div className="alert alert-light" role="alert">
                                {message}
                              </div>


                            </div>

                            <div className="form-group">
                              <label>
                                <strong>Status:</strong>
                              </label>
                              {currentPelle.published ? "Published" : "En cours de modification"}
                            </div>
                          </form>
                        </div>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.removePelle}
                        >
                          Supprimer
                        </button>
                        <a
                            className="btn badge-success"
                            onClick={this.upload}
                        >
                          Mettre à jour
                        </a>
                      </div>

                    </div>)}
                {currentPelle.published === true && (
                    <div>
                      <a
                          href="http://gestion.attirail.fr/pelles"
                      >
                        Revenir à la liste des pelles
                      </a>

                    </div>)}

              </div>

          ) : (
              <div>
                <br/>
                <p>Erreur cette pelle n'éxiste pas</p>
                <a
                    href="http://gestion.attirail.fr/pelles"
                >
                  Revenir à la liste des pelles
                </a>
              </div>
          )
          }
        </div>
    );
  }
}

export default connect(null, { updatePelle, deletePelle })(Pelle);