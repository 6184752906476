import {
CREATE_CATEGORIE,
RETRIEVE_CATEGORIES,
UPDATE_CATEGORIE,
DELETE_CATEGORIE,
DELETE_ALL_CATEGORIES
} from "./types";

import CategoriesDataService from "../services/categorie.service";

export const createCategories = (Nom, Img1) => async (dispatch) => {
  try {
    const res = await CategoriesDataService.create({ Nom , Img1});

    dispatch({
      type: CREATE_CATEGORIE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const res = await CategoriesDataService.addFile({file});

    dispatch({
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};




export const retrieveCategories = () => async (dispatch) => {

  try {
    const res = await CategoriesDataService.getAll();

    dispatch({
      type: RETRIEVE_CATEGORIES,
      payload: res.data,

    });
  } catch (err) {
    console.log(err);
  }
};

export const updateCategorie = (id, data) => async (dispatch) => {
  try {
    const res = await CategoriesDataService.update(id, data);

    dispatch({
      type: UPDATE_CATEGORIE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCategorie = (id) => async (dispatch) => {
  try {
    await CategoriesDataService.delete(id);

    dispatch({
      type: DELETE_CATEGORIE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllCategories = () => async (dispatch) => {
  try {
    const res = await CategoriesDataService.deleteAll();

    dispatch({
      type: DELETE_ALL_CATEGORIES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findCategoriesByTitle = (nom) => async (dispatch) => {
  try {
    const res = await CategoriesDataService.findByTitle(nom);

    dispatch({
      type: RETRIEVE_CATEGORIES,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
};