import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveDumpers,
  findDumpersByTitle,
  deleteAllDumpers,
} from "../actions/dumpers";
import { Link } from "react-router-dom";

class DumpersList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveDumper = this.setActiveDumper.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllDumpers = this.removeAllDumpers.bind(this);

    this.state = {
      currentDumper: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveDumpers();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentDumper: null,
      currentIndex: -1,
    });
  }

  setActiveDumper(dumper, index) {
    this.setState({
      currentDumper: dumper,
      currentIndex: index,
    });
  }

  removeAllDumpers() {
    this.props
      .deleteAllDumpers()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findDumpersByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentDumper, currentIndex } = this.state;
    const { dumpers } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher un dumper"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Rechercher
              </button>
              <div>

              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des dumpers</h4>

          <ul className="list-group">
            {dumpers &&
            dumpers.map((dumper, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActiveDumper(dumper)}
                    key={dumper.id}

                >
                  <ul> {dumper.Nom}
                  </ul>
                  <tr>
                  <td><img className="img" src={dumper.Img1} alt={dumper.Img1}/></td>
                  <td><img className="img" src={dumper.Img2} alt={dumper.Img2}/></td>
                  <td><img className="img" src={dumper.Img3} alt={dumper.Img3}/></td>
                  </tr>

                </li>


            ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllDumpers}
          >
            Supprimer tous les dumpers
          </button>
        </div>
        <div className="col-md-6">
          {currentDumper ? (
            <div>
              <h4>Dumpers</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentDumper.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentDumper.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/dumpers/" + currentDumper.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Cliquer sur un dumper ou  </p>
              <p> <a href="https://gestion.attirail.fr/adddumpers"> Ajouter un dumper</a></p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dumpers: state.dumpers,
  };
};

export default connect(mapStateToProps, {
  retrieveDumpers,
  findDumpersByTitle,
  deleteAllDumpers,
})(DumpersList);
