import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveVentes,
  findVentesByTitle,
  deleteAllVentes,
} from "../actions/ventes";
import { Link } from "react-router-dom";

class VentesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveVente = this.setActiveVente.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllVentes = this.removeAllVentes.bind(this);

    this.state = {
      currentVente: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveVentes();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentVente: null,
      currentIndex: -1,
    });
  }

  setActiveVente(vente, index) {
    this.setState({
      currentVente: vente,
      currentIndex: index,
    });
  }

  removeAllVentes() {
    this.props
      .deleteAllVentes()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findVentesByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentVente, currentIndex } = this.state;
    const { ventes } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Rechercher un produit"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Rechercher
              </button>
              <div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des produits à vendre</h4>

          <ul className="list-group">
            {ventes &&
            ventes.map((vente, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActiveVente(vente)}
                    key={vente.id}

                >
                  <ul>{vente.Nom}
                  </ul>
                  <tr>
                  <td><img className="img" src={vente.Img1} alt={vente.Img1}/></td>
                  <td><img className="img" src={vente.Img2} alt={vente.Img2}/></td>
                  <td><img className="img" src={vente.Img3} alt={vente.Img3}/></td>
                  </tr>
                </li>


            ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllVentes}
          >
            Supprimer toutes les ventes
          </button>
        </div>
        <div className="col-md-6">
          {currentVente ? (
            <div>
              <h4>Ventes</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentVente.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentVente.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/ventes/" + currentVente.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Cliquer sur un produit ou  </p>
              <p>  <a className="nav-item"
                      href="https://gestion.attirail.fr/addventes" type="button"> Ajouter un produit à vendre </a>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ventes: state.ventes,
  };
};

export default connect(mapStateToProps, {
  retrieveVentes,
  findVentesByTitle,
  deleteAllVentes,
})(VentesList);
