import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNacelle, deleteNacelle } from "../actions/nacelles";
import NacellesDataService from "../services/nacelle.service";
import UploadService from "../services/nacelle.service";

class Nacelle extends Component {
  constructor(props) {
    super(props);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangeHauteurM = this.onChangeHauteurM.bind(this);
    this.onChangeChargeM= this.onChangeChargeM.bind(this);

    this.onChangeLongueur= this.onChangeLongueur.bind(this);
    this.onChangeLargeur = this.onChangeLargeur.bind(this);
    this.onChangeHauteur = this.onChangeHauteur.bind(this);

    this.getNacelle = this.getNacelle.bind(this);
    this.removeNacelle = this.removeNacelle.bind(this);

    this.state = {
      currentNacelle: {
        id: null,
        Nom: "",
        Hauteur_max:"",
        Categorie:"",
        Charge_max:"",
        Longeur:"",
        Largeur:"",
        Hauteur:"",
        Img1:"",
        Img2:"",
        Img3:"",
        Fiche_technique: "",
        published: false,
      },
      message: "",
      selectedFiles1: undefined,
      selectedFiles2: undefined,
      selectedFiles3: undefined,
      selectedFiles4: undefined,
      progress: 0,
      fileName:"",
      progress2: 0,
      fileName2:"",
      progress3: 0,
      fileName3:"",
      progress4: 0,
      fileName4:"",
      fileInfos: [],

    };
  }

  componentDidMount() {
    this.getNacelle(this.props.match.params.id);
  }

  onChangeNom(e) {
    const Nom = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Nom: Nom,
        },
      };
    });
  }

  onChangeHauteurM(e) {
    const Hauteur_max = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Hauteur_max: Hauteur_max,
        },
      };
    });
  }

  onChangeChargeM(e) {
    const Charge_max = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Charge_max: Charge_max,
        },
      };
    });
  }


  onChangeHauteur(e) {
    const Hauteur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Hauteur: Hauteur,
        },
      };
    });
  }

  onChangeLongueur(e) {
    const Longueur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Longueur: Longueur,
        },
      };
    });
  }

  onChangeLargeur(e) {
    const Largeur = e.target.value;

    this.setState(function (prevState) {
      return {
        currentNacelle: {
          ...prevState.currentNacelle,
          Largeur: Largeur,
        },
      };
    });
  }

  selectFile1 =(event) =>{
    this.setState({
          selectedFiles1: event.target.files,
        }
    );
  }

  selectFile2 =(event) =>{
    this.setState({
          selectedFiles2: event.target.files,
        }
    );
  }

  upload = () => {


    if (this.state.selectedFiles1) {
      if ((this.state.selectedFiles1).length < 3) {
        this.setState({
          message: "Veuillez selectionner 3 images !",
        })
      }

      if ((this.state.selectedFiles1).length === 3) {

        if (this.state.selectedFiles1 && this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName1 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile3.name;
          let fileName4 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile4.name;
          var files = [currentFile1, currentFile2, currentFile3, currentFile4];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,
            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentNacelle: {
                ...prevState.currentNacelle,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateNacelle(this.state.currentNacelle.id, this.state.currentNacelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The nacelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
            selectedFiles2: undefined,

          });

        }


        if (this.state.selectedFiles2) {
          let currentFile4 = this.state.selectedFiles2[0];
          let fileName4 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile4.name;

          this.setState({
            progress: 0,

            currentFile4: currentFile4,
          });
          this.setState(function (prevState) {
            return {
              currentNacelle: {
                ...prevState.currentNacelle,
                Fiche_technique: fileName4,

              },
            };
          });

          UploadService.upload(currentFile4, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateNacelle(this.state.currentNacelle.id, this.state.currentNacelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The nacelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles2: undefined,
          });

        }

        if (this.state.selectedFiles1 && !this.state.selectedFiles2) {
          let currentFile1 = this.state.selectedFiles1[0];
          let currentFile2 = this.state.selectedFiles1[1];
          let currentFile3 = this.state.selectedFiles1[2];

          let fileName1 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile1.name;
          let fileName2 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile2.name;
          let fileName3 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile3.name;

          var files = [currentFile1, currentFile2, currentFile3];
          this.setState({
            progress: 0,
            currentFile1: currentFile1,
            currentFile2: currentFile2,
            currentFile3: currentFile3,

          });
          this.setState(function (prevState) {
            return {
              currentNacelle: {
                ...prevState.currentNacelle,
                Img1: fileName1,
                Img2: fileName2,
                Img3: fileName3,


              },
            };
          });

          UploadService.upload(files, (event) => {
            this.setState({
              progress: Math.round((100 * event.loaded) / event.total),
            });
          }).then((response) => {
            this.setState({
                  message: response.data.message,
                }
            );
            return UploadService.getFiles();
          })
              .then((files) => {
                this.setState({
                  fileInfos: files.data,
                });
              }).then(() => {
            this.props
                .updateNacelle(this.state.currentNacelle.id, this.state.currentNacelle)
                .then((response) => {
                  console.log(response);

                  this.setState({message: "The nacelle was updated successfully!"});
                })
                .catch((e) => {
                  console.log(e);
                });
          })
              .catch(() => {
                this.setState({
                  progress: 0,
                  message: "Could not upload the file!",
                  currentFile: undefined,
                });
              });


          this.setState({
            selectedFiles1: undefined,
          });

        }

        this.setState(function (prevState) {
          return {
            currentNacelle: {
              ...prevState.currentNacelle,
              published: true,
            },
          };
        })

      }


    }
    if (!this.state.selectedFiles1 && !this.state.selectedFiles2) {
      this.props
          .updateNacelle(this.state.currentNacelle.id, this.state.currentNacelle)
          .then((response) => {
            console.log(response);

            this.setState({message: "The nacelle was updated successfully!"});
          })
          .catch((e) => {
            console.log(e);
          });

      this.setState(function (prevState) {
        return {
          currentNacelle: {
            ...prevState.currentNacelle,
            published: true,
          },
        };
      })
    }

    if (!this.state.selectedFiles1 && this.state.selectedFiles2) {

      let currentFile4 = this.state.selectedFiles2[0];

      let fileName4 = 'https://api.attirail.fr/api/fichiernacelles/files/' + currentFile4.name;
      var files = [ currentFile4];
      this.setState({
        progress: 0,

      });
      this.setState(function (prevState) {
        return {
          currentNacelle: {
            ...prevState.currentNacelle,
            Fiche_technique: fileName4,

          },
        };
      });

      UploadService.upload(files, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }).then((response) => {
        this.setState({
              message: response.data.message,
            }
        );
        return UploadService.getFiles();
      })
          .then((files) => {
            this.setState({
              fileInfos: files.data,
            });
          }).then(() => {
        this.props
            .updateNacelle(this.state.currentNacelle.id, this.state.currentNacelle)
            .then((response) => {
              console.log(response);

              this.setState({message: "The nacelle was updated successfully!"});
            })
            .catch((e) => {
              console.log(e);
            });
      })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
            });
          });


      this.setState({
        selectedFiles1: undefined,
        selectedFiles2: undefined,

      });

      this.setState(function (prevState) {
        return {
          currentNacelle: {
            ...prevState.currentNacelle,
            published: true,
          },
        };
      })
    }



  }


  getNacelle(id) {
    NacellesDataService.get(id)
      .then((response) => {
        this.setState({
          currentNacelle: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  removeNacelle() {
    this.props
      .deleteNacelle(this.state.currentNacelle.id)
      .then(() => {
        this.props.history.push("/nacelles");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentNacelle , currentFile1, currentFile2, currentFile3, currentFile4,progress,
      message} = this.state;

    return (
        <div>
          {currentNacelle ? (
              <div>
                {!currentNacelle.published && (
                    <div>
                      <div className="edit-form">
                        <div>
                          <h4>Mise à jour de : {currentNacelle.Nom}</h4>
                          <form>
                            <div className="form-group">
                              <label htmlFor="Nom">Nom</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Nom"
                                  value={currentNacelle.Nom}
                                  onChange={this.onChangeNom}
                              />
                              <label htmlFor="Charge_max">Charge Max</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Charge_max"
                                  value={currentNacelle.Charge_max}
                                  onChange={this.onChangeChargeM}
                              />
                              <label htmlFor="Hauteur_max">Hauteur Max</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Hauteur_max"
                                  value={currentNacelle.Hauteur_max}
                                  onChange={this.onChangeHauteurM}
                              />
                              <label htmlFor="Longueur">Longueur</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Longueur"
                                  value={currentNacelle.Longueur}
                                  onChange={this.onChangeLongueur}
                              />
                              <label htmlFor="Largeur">Largeur</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Largeur"
                                  value={currentNacelle.Largeur}
                                  onChange={this.onChangeLargeur}
                              />
                              <label htmlFor="Hauteur">Hauteur </label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="Hauteur"
                                  value={currentNacelle.Hauteur}
                                  onChange={this.onChangeHauteur}
                              />
                            </div>
                            <div>
                              {currentFile1 && currentFile2 && currentFile3 && currentFile4 && (
                                  <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-info progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{width: progress + "%"}}
                                    >
                                      {progress}%
                                    </div>
                                  </div>
                              )}
                              <p>Si MAJ, veuillez selectionner 3 images </p>
                              <label className="btn btn-default">
                                <input type="file" max="3" multiple='3' encType='multipart/form-data' onChange={this.selectFile1}/>
                              </label>
                              <p>Fiche technique : </p>
                              <label className="btn btn-default>">
                                <input type="file"  onChange={this.selectFile2}/>
                              </label>

                              <div className="alert alert-light" role="alert">
                                {message}
                              </div>
                            </div>

                            <div className="form-group">
                              <label>
                                <strong>Status:</strong>
                              </label>
                              {currentNacelle.published ? "Published" : "En cours de modification"}
                            </div>
                          </form>
                        </div>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.removeNacelle}
                        >
                          Supprimer
                        </button>
                        <a
                            className="btn badge-success"
                            onClick={this.upload}
                        >
                          Mettre à jour
                        </a>
                      </div>

                    </div>   )}
                {currentNacelle.published===true && (
                    <div>
                      <a
                          href="https://gestion.attirail.fr/nacelles"
                      >
                        Revenir à la liste des nacelles
                      </a>

                    </div>)}

              </div>

          ) : (
              <div>
                <br />
                <p>Erreur cette nacelle n'éxiste pas</p>
                <a
                    href="https://gestion.attirail.fr/nacelles"
                >
                  Revenir à la liste des nacelles
                </a>
              </div>
          )
          }
        </div>
    );
  }
}

export default connect(null, { updateNacelle, deleteNacelle })(Nacelle);
