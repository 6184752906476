import { combineReducers } from "redux";
import categories from "./categories";
import pelles from "../../Pelle/reducers/pelles";
import nacelles from "../../Nacelle/reducers/nacelle";
import dumpers from "../../Dumper/reducers/dumper";
import remorques from "../../Remorque/reducers/remorque";
import autres from "../../Autre/reducers/autres";
import ventes from "../../Vente/reducers/ventes"
import chenillards from "../../Chenillard/reducers/chenillard";

import auth from "../../Session/reducers/auth";
import message from "../../Session/reducers/message";
import engins from "../../Engin/reducers/engins";

export default combineReducers({
  pelles,
  categories,
  nacelles,
  dumpers,
  remorques,
  autres,
  chenillards,
  auth,
  message,
  ventes,
  engins,

});
