import {
  CREATE_VENTE,
  RETRIEVE_VENTES,
  UPDATE_VENTE,
  DELETE_VENTE,
  DELETE_ALL_VENTES
} from "../actions/types";

const initialState = [];

function venteReducer(ventes = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VENTE:
      return [...ventes, payload];

    case RETRIEVE_VENTES:
      return payload;

    case UPDATE_VENTE:
      return ventes.map((vente) => {
        if (vente.id === payload.id) {
          return {
            ...vente,
            ...payload,
          };
        } else {
          return vente;
        }
      });

    case DELETE_VENTE:
      return ventes.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_VENTES:
      return [];

    default:
      return ventes;
  }
}

export default venteReducer;