import {
  CREATE_CATEGORIE,
  RETRIEVE_CATEGORIES,
  UPDATE_CATEGORIE,
  DELETE_CATEGORIE,
  DELETE_ALL_CATEGORIES
} from "../actions/types";

const initialState = [];

function categorieReducer(categories = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CATEGORIE:
      return [...categories, payload];

    case RETRIEVE_CATEGORIES:
      return payload;

    case UPDATE_CATEGORIE:
      return categories.map((categorie) => {
        if (categorie.id === payload.id) {
          return {
            ...categorie,
            ...payload,
          };
        } else {
          return categorie;
        }
      });

    case DELETE_CATEGORIE:
      return categories.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_CATEGORIES:
      return [];

    default:
      return categories;
  }
}

export default categorieReducer;