import {
  CREATE_AUTRE,
  RETRIEVE_AUTRES,
  UPDATE_AUTRE,
  DELETE_AUTRE,
  DELETE_ALL_AUTRES
} from "../actions/types";

const initialState = [];

function autreReducer(autres = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_AUTRE:
      return [...autres, payload];

    case RETRIEVE_AUTRES:
      return payload;

    case UPDATE_AUTRE:
      return autres.map((autre) => {
        if (autre.id === payload.id) {
          return {
            ...autre,
            ...payload,
          };
        } else {
          return autre;
        }
      });

    case DELETE_AUTRE:
      return autres.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_AUTRES:
      return [];

    default:
      return autres;
  }
}

export default autreReducer;