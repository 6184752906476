import http from "../../http-common";

class EnginsDataService {

  upload(files, onUploadProgress) {
    let formData = new FormData();

    for(let i = 0; i < files.length; i++){
      formData.append('file', files[i]);
    }

    return http.post("/fichierengins/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/fichierengins/getListFiles");
  }

  getAll() {
    return http.get("/engins");
  }

  get(id) {
    return http.get(`/engins/${id}`);
  }

  create(data) {
    return http.post("/engins", data);

  }

  update(id, data) {
    return http.put(`/engins/${id}`, data);
  }

  delete(id) {
    return http.delete(`/engins/${id}`);
  }

  deleteAll() {
    return http.delete(`/engins`);
  }

  findByTitle(Nom) {
    return http.get(`/engins?nom=${Nom}`);
  }
}

export default new EnginsDataService();