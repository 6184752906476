import React, { Component } from "react";
import { connect } from "react-redux";
import {
  retrieveNacelles,
  findNacellesByTitle,
  deleteAllNacelles,
} from "../actions/nacelles";
import { Link } from "react-router-dom";

class NacellesList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchNom = this.onChangeSearchNom.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveNacelle = this.setActiveNacelle.bind(this);
    this.findByNom = this.findByNom.bind(this);
    this.removeAllNacelles = this.removeAllNacelles.bind(this);

    this.state = {
      currentNacelle: null,
      currentIndex: -1,
      searchNom: "",
    };
  }

  componentDidMount() {
    this.props.retrieveNacelles();

  }

  onChangeSearchNom(e) {
    const searchNom = e.target.value;

    this.setState({
      searchNom: searchNom,
    });
  }

  refreshData() {
    this.setState({
      currentNacelle: null,
      currentIndex: -1,
    });
  }

  setActiveNacelle(nacelle, index) {
    this.setState({
      currentNacelle: nacelle,
      currentIndex: index,
    });
  }

  removeAllNacelles() {
    this.props
      .deleteAllNacelles()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  findByNom() {
    this.refreshData();

    this.props.findNacellesByTitle(this.state.searchNom);
  }

  render() {
    const { searchNom, currentNacelle, currentIndex } = this.state;
    const { nacelles } = this.props;


    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title"
              value={searchNom}
              onChange={this.onChangeSearchNom}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.findByNom}
              >
                Search
              </button>

            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Liste des nacelles</h4>

          <ul className="list-group">
            {nacelles &&
            nacelles.map((nacelle, index) => (

                <li
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                    }

                    onClick={() => this.setActiveNacelle(nacelle)}
                    key={nacelle.id}

                >
                  <ul>{nacelle.Nom}
                  </ul>
                  <tr>
                  <td><img className="img" src={nacelle.Img1} alt={nacelle.Img1}/></td>
                  <td><img className="img" src={nacelle.Img2} alt={nacelle.Img2}/></td>
                  <td><img className="img" src={nacelle.Img3} alt={nacelle.Img3}/></td>
                  </tr>
                </li>


            ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllNacelles}
          >
            Supprimer toutes les nacelles
          </button>
        </div>
        <div className="col-md-6">
          {currentNacelle ? (
            <div>
              <h4>Nacelles</h4>
              <div>
                <label>
                  <strong>Nom:</strong>
                </label>{" "}
                {currentNacelle.Nom}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentNacelle.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/nacelles/" + currentNacelle.id}
                className="badge badge-warning"
              >
                Editer
              </Link>
            </div>
          ) : (
              <div>
                <br />
                <p>Cliquer sur une nacelle ou  </p>
                <p>  <a className="nav-item"
                        href="https://gestion.attirail.fr/addnacelles" type="button"> Ajouter une nacelle </a>
                </p>
              </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nacelles: state.nacelles,
  };
};

export default connect(mapStateToProps, {
  retrieveNacelles,
  findNacellesByTitle,
  deleteAllNacelles,
})(NacellesList);
